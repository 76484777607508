// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 应用列表查询接口
export const getAppList = (data?: any) => request('/payAdminServer/app/list', null, 'POST_JSON', data)

// 筛选项下拉列表查询接口
export const getAllFilters = (data: any) => request('/payAdminServer/paid/event/filters', null, 'POST_JSON', data)

// 通过 dbtId 查询支付渠道
export const getPayChnlByDbtIds = (data: any) =>
  request('/payAdminServer/app/getPayChnlByDbtIds', null, 'POST_JSON', data)

// 监控数据看板
export const getMonitorData = (data: any) =>
  request('/payAdminServer/metrics/selectPayMetrics', null, 'POST_JSON', data)

// 获取监控数据看板下拉项
export const getMonitorFiltes = (data: any) => request('/payAdminServer/metrics/filters', null, 'POST_JSON', data)
